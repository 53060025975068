import React from "react"
import HeaderFeaturedImage from "../common/HeaderFeaturedImage"
import Link from "../common/Link"
import LokalerMeny from "./LokalerMeny"

import LokalerThumbnails from "./LokalerThumbnails"
import Moblering from "./Moblering"

export default function LokalerSinglePage({ data, lang }) {
  const { title, lokalinfo, featuredImage } = data.wpPage
  const {
    kortBeskrivningLokal,
    langreBeskrivningLokal,
    mobleringLokal,
    thumbnails,
    largeImages,
  } = lokalinfo

  return (
    <div className="">
      {featuredImage && (
        <HeaderFeaturedImage
          featuredImage={featuredImage}
          title={title}
          subTitle={kortBeskrivningLokal}
        />
      )}

      <div className="page-inner">
        <div className="mb-4 lg:mb-16 flex flex-col w-full items-center">
          <LokalerMeny lang={lang} />
        </div>
        <div className="flex flex-col lg:flex-row  gap-4 lg:gap-8">
          <div className="flex-1 flex flex-col gap-4 lg:gap-8">
            {langreBeskrivningLokal && (
              <div className=" text-xl">
                {" "}
                <div
                  className="wp-content"
                  dangerouslySetInnerHTML={{ __html: langreBeskrivningLokal }}
                />
              </div>
            )}
            {thumbnails && (
              <LokalerThumbnails
                thumbnails={thumbnails}
                largeImages={largeImages}
              />
            )}
            <div className="">
              <p>
                {lang === "sv"
                  ? "Intresserad av denna lokal?"
                  : "Interested in this venue? "}
                <Link
                  to={
                    lang === "sv"
                      ? "/information/kontakt/"
                      : "/en/information-2/contact"
                  }
                >
                  {lang === "sv" ? "Kontakta oss" : "Contact us"}
                </Link>
                !
              </p>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-4 lg:gap-8 bg-thirteenth/10 p-4 lg:p-8">
            {mobleringLokal && <Moblering mobleringLokal={mobleringLokal} />}
          </div>
        </div>
      </div>
    </div>
  )
}
