import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../common/Link"
import { Menu, Transition } from "@headlessui/react"
import { Fragment, useEffect, useRef, useState } from "react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"

export default function LokalerMeny({ lang }) {
  const data = useStaticQuery(graphql`
    query {
      menuSE: allWpPage(filter: { parentDatabaseId: { eq: 7 } }) {
        nodes {
          uri
          title
        }
      }
      menuEN: allWpPage(filter: { parentDatabaseId: { eq: 796 } }) {
        nodes {
          uri
          title
        }
      }
    }
  `)

  const menuItems = lang === "en" ? data.menuEN.nodes : data.menuSE.nodes

  return (
    <div className="flex flex-row ">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="relative z-40 inline-flex w-full  items-center justify-center rounded-md bg-primary  px-4 py-2  text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {lang === "en" ? "Our Venues" : "Snabbmeny lokaler"}
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-white"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-40 left-0 mt-2 p-2 origin-top-left  rounded-md bg-white  ring-1 ring-primary ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {menuItems.map((menuItem, i) => {
                return (
                  <Menu.Item key={i}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? " text-primary" : "text-gray-900"
                        } group flex w-full items-center px-2 py-2`}
                      >
                        <Link to={menuItem.uri}>
                          <div className="whitespace-nowrap block w-full">
                            {menuItem.title}
                          </div>
                        </Link>
                      </button>
                    )}
                  </Menu.Item>
                )
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      {/* {menuItems.map((menuItem, i) => {
        return <MenuItem key={i} menuItem={menuItem} />
      })} */}
    </div>
  )
}

// function MenuItem({ menuItem }) {
//   const { uri, title } = menuItem
//   return (
//     <Link to={uri} title={title} activeClassName="text-secondary">
//       {title}
//     </Link>
//   )
// }
