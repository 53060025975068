import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Lightbox from "yet-another-react-lightbox"

export default function LokalerThumbnails({ thumbnails, largeImages }) {
  const [index, setIndex] = React.useState(-1)

  return (
    <div>
      <div className="grid grid-cols-3 gap-2 lg:gap-4">
        {thumbnails?.map((thumbnail, i) => {
          return (
            <div key={i} onClick={({ index }) => setIndex(i)}>
              <Thumbnail thumbnail={thumbnail} />
            </div>
          )
        })}
      </div>
      <div className="max-h-[50vh]">
        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={largeImages}
          render={{
            slide: (image, offset, rect) => {
              // const width = Math.round(
              //   Math.min(rect.width, (rect.height / image.height) * image.width)
              // )
              // const height = Math.round(
              //   Math.min(rect.height, (rect.width / image.width) * image.height)
              // )
              return (
                <img
                  alt=""
                  src={image.bildBilder?.localFile.publicURL}
                  loading="eager"
                  className=""
                />
              )
            },
          }}
        />
      </div>
    </div>
  )
}

function Thumbnail({ thumbnail }) {
  const img = getImage(thumbnail.bildBilder?.localFile.childImageSharp)

  return (
    <div>
      <GatsbyImage image={img} className="w-full h-full cursor-pointer" />
    </div>
  )
}
