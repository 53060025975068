import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Moblering({ mobleringLokal }) {
  const {
    beksrivningMoblering,
    sittningstypMoblering,
    sittningstypFleraRum,
    rubrikMoblering,
  } = mobleringLokal
  const sittings = sittningstypMoblering
  const sittingsMultiples = sittningstypFleraRum

  return (
    <div>
      {" "}
      <div className="flex flex-col gap-4 lg:gap-8">
        <h2>{rubrikMoblering}</h2>
        {beksrivningMoblering && (
          <div>
            <div
              className="wp-content"
              dangerouslySetInnerHTML={{ __html: beksrivningMoblering }}
            />
          </div>
        )}
        {sittings && (
          <>
            <div className="grid lg:grid-cols-4 gap-4 lg:gap-8">
              {sittings.map((sitting, i) => {
                return <Sitting key={i} sitting={sitting} />
              })}
            </div>
          </>
        )}
        {sittingsMultiples && (
          <>
            <div className="flex flex-col items-start gap-4 lg:gap-8">
              {sittingsMultiples.map((sitting, i) => {
                return <SittingMultiple key={i} sitting={sitting} />
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

function Sitting({ sitting }) {
  const { bild, namnSittningstyp, kapacitetSittningstyp } = sitting
  return (
    <div className="flex flex-col items-center">
      <div>
        <GatsbyImage
          image={getImage(bild?.localFile.childImageSharp)}
          className="mix-blend-multiply"
        />
      </div>
      <p className="font-bold">{namnSittningstyp}</p>
      <p>{kapacitetSittningstyp}</p>
    </div>
  )
}
function SittingMultiple({ sitting }) {
  const { namnPaRum, storlekFleraRumSub, sittningstypFleraRumSub } = sitting
  return (
    <div className="flex flex-col items-start justify-start gap-4  w-full">
      <div className="w-full text-center bg-thirteenth/40 font-bold p-2 lg:p-4">
        {namnPaRum}
      </div>
      {sittningstypFleraRumSub && (
        <div className="grid grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-8">
          {sittningstypFleraRumSub.map(sitting => {
            return <SittingMultipleSingle key={sitting.id} sitting={sitting} />
          })}
        </div>
      )}
      {/* <div>
        <GatsbyImage
          image={getImage(bild.localFile.childImageSharp)}
          className="mix-blend-multiply"
        />
      </div>
      <p className="font-bold">{namnSittningstyp}</p>
      <p>{kapacitetSittningstyp}</p> */}
    </div>
  )
}

function SittingMultipleSingle({ sitting }) {
  const { bildSittningstypSub, namnSittningstypSub, kapacitetSittningstypSub } =
    sitting
  return (
    <div className="flex flex-col items-center">
      <div className="">
        <GatsbyImage
          image={getImage(bildSittningstypSub.localFile.childImageSharp)}
          className="mix-blend-multiply"
        />
      </div>
      <p className="font-bold">{namnSittningstypSub}</p>
      <p>{kapacitetSittningstypSub}</p>
    </div>
  )
}
