import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import LokalerSinglePage from "../components/lokaler/LokalerSinglePage"

export default function LokalTemplate({ data }) {
  const lang = data.wpPage.language.slug
  const translation = data.wpPage.translations[0]?.uri

  return (
    <div className="">
      <Seo title={data.wpPage.title} />

      <Layout lang={lang} translation={translation}>
        <LokalerSinglePage lang={data.wpPage.language.slug} data={data} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.66, layout: CONSTRAINED)
            }
          }
        }
      }
      language {
        slug
      }
      translations {
        uri
      }
      lokalinfo {
        kapacitetLokal
        kortBeskrivningLokal
        langreBeskrivningLokal
        storlekLokal
        mobleringLokal {
          rubrikMoblering
          beksrivningMoblering
          sittningstypMoblering {
            bild {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            namnSittningstyp
            kapacitetSittningstyp
          }
          sittningstypFleraRum {
            namnPaRum
            storlekFleraRumSub
            sittningstypFleraRumSub {
              kapacitetSittningstypSub
              namnSittningstypSub
              bildSittningstypSub {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
        thumbnails: bilderLokal {
          bildBilder {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1

                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        largeImages: bilderLokal {
          bildBilder {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
                original {
                  src
                }
              }
            }
          }
        }
      }
      title
      uri
    }
  }
`
